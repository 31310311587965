import React from 'react'
import './BaseFooter.less'




class BaseFooter extends React.Component{

    render(){
        return (
            <>
                <div className='basefooter'>
                </div>
            </>
        )
    }
} 





export default BaseFooter;






















