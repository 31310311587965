
import React, { Component } from 'react';
import { Row , Col , Input , Pagination } from 'antd';
import common from "../../../utils/common"
import { getArticleList }  from "../../../reducers/article/action"
import ArticleMat from '../ArticleMat/ArticleMat'
import { connect } from 'react-redux'
import {
    withRouter
} from "react-router-dom";

import baseConfig from "../../../baseConfig.js"
import "./ArticleList.less"


class ArticleList extends Component {

    constructor(props){
        super(props)
        this.state={
            keyword : '',
            page : 1,
            pagenum : 6,
            total : 0
        }
    }

    componentDidMount(){
        this.setState({total : this.props.article.total})
        this.getData()
    }

    
    getData(){
        let param = {
            keyword : this.state.keyword,
            page : this.state.page,
            pagenum : this.state.pagenum
        }
        const {getArticleList} = this.props;
        getArticleList(param);
    }

    onSearch(value){
        this.setState({keyword : value , page : 1} , ()=>{
            this.getData();
        })
    }

    onChangePage(page , pageSize){
        this.setState({
            page : page
        } , ()=>{
            this.getData()
        })
    }

    onMainPicClick(val){
        this.props.onMainPicClick(val)
    }

    render(){

        const { Search } = Input;
        return (
            <>
                <div className={"PicSearchList"}>
                    <Row align='middle' gutter={[20 , 0]}>
                        <Col span={8}>
                            <Search
                            placeholder="请输入关键词"
                            allowClear
                            enterButton="搜索"
                            onSearch={this.onSearch.bind(this)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className={'art-search-list'}>
                                {
                                    this.props.article.data.map((item , index)=>{
                                        var date = common.dateFtt("yyyy-MM-dd hh:mm:ss" , new Date((parseInt(item.updated_at))*1000))
                                        return (
                                            <ArticleMat  
                                                title={item.article_name}
                                                img={baseConfig.picUrl + item.article_cover}
                                                date={date}
                                                key={index}
                                                id={item.article_id}
                                                footer = {false}
                                                onMainPicClick={this.onMainPicClick.bind(this)}
                                            ></ArticleMat>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Pagination 
                            defaultCurrent={this.state.page} 
                            current={this.state.page} 
                            pageSize={this.state.pagenum}
                            total={this.state.total}  
                            onChange={this.onChangePage.bind(this)}/>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

const stateToProps = (state)=>{
    return {
        article : state.article,
    }
}

const dispatchToProps =(dispatch)=>{
    return {
        getArticleList(val){
            dispatch(getArticleList(val))
        },   
    }
}


export default connect(stateToProps, dispatchToProps)(withRouter(ArticleList));






