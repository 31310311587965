import { ARTICLE_ACTION } from "./action"


const article = (state = { data : [] , total : 0 } , action)=>{
    switch (action.type) {
        case ARTICLE_ACTION.UPDATE_LIST:
              var info = action.info;
              if(info.code === 200){
                    var data = info.data
                    var obj = JSON.parse(JSON.stringify(Object.assign(state , data)))
                    return obj
              }else{
                    return state
              }
        default:
          return state;
      }
}

export default article;