import {getServerPubs} from "../../services/pub"


export const PUB_ACTION = {

    UPDATE : 'UPDATE_PUB'
}


export const getPubs= (param)=>{
    return (dispatch , getState)=>{
        return getServerPubs(param).then((resp)=>{
            dispatch(updatePubs(resp.data))
        }).catch((err)=>{
            console.log(err)
        })
    }
}


const updatePubs = (info)=>({
    type : PUB_ACTION.UPDATE,
    info : info
})
