import React from 'react'
import { Row , Col , Input , Pagination } from 'antd';
import common from "../../../utils/common"
import { getMatPicList }  from "../../../reducers/mat/action"
import PicMat from '../PicMat/PicMat'
import { connect } from 'react-redux'
import baseConfig from "../../../baseConfig.js"

import {
    withRouter
} from "react-router-dom";


import "./PicSearchList.less"

 
class PicSearchList extends React.Component{

    constructor(props){
        super(props)
        this.state={
            keyword : '',
            page : 1,
            pagenum : 6,
            total : 0
        }
    }

    componentDidMount(){
        this.setState({total : this.props.pic.total})
        this.getData()
    }

    getData(){
        let param = {
            keyword : this.state.keyword,
            page : this.state.page,
            pagenum : this.state.pagenum
        }
        const {getMatPicList} = this.props;
        getMatPicList(param);
    }

    onSearch(value){
        this.setState({keyword : value} , ()=>{
            this.getData();
        })
    }

    onChangePage(page , pageSize){
        this.setState({
            page : page
        } , ()=>{
            this.getData()
        })
    }

    onMainPicClick(val){
        const { pic } = this.props;
        let obj = null;
        for(let item of pic.data){
            if(val === item.image_id){
                obj = item;
            }
        }
        this.props.onMainPicClick(obj)
    }

    render(){

        const { Search } = Input;
        return (
            <>
                <div className={"PicSearchList"}>
                    <Row align='middle' gutter={[20 , 0]}>
                        <Col span={8}>
                            <Search
                            placeholder="请输入关键词"
                            allowClear
                            enterButton="搜索"
                            onSearch={this.onSearch.bind(this)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className={'pic-search-list'}>
                                {
                                    this.props.pic.data.map((item , index)=>{
                                        var date = common.dateFtt("yyyy-MM-dd hh:mm:ss" , new Date((parseInt(item.updated_at))*1000))
                                        return (
                                            <PicMat  
                                                title={item.file_name}
                                                img={baseConfig.picUrl + item.local_url}
                                                uri={item.local_url}
                                                date={date}
                                                key={index}
                                                pic_id={item.image_id}
                                                footer = {false}
                                                onMainPicClick={this.onMainPicClick.bind(this)}  
                                            ></PicMat>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Pagination 
                            defaultCurrent={this.state.page} 
                            current={this.state.page} 
                            pageSize={this.state.pagenum}
                            total={this.state.total}  
                            onChange={this.onChangePage.bind(this)}/>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}



const stateToProps = (state)=>{
    return {
        pic : state.mat,
    }
}

const dispatchToProps =(dispatch)=>{
    return {
        getMatPicList(val){
            dispatch(getMatPicList(val))
        },   
    }
}


export default connect(stateToProps, dispatchToProps)(withRouter(PicSearchList));

