import React from 'react'
import { Row , Col , Input , Button , Tabs , Pagination } from 'antd';
import PublicContent from "../../components/ContentCom/index"
// import NoData from "../../components/NoData/NoData"
import { connect } from 'react-redux'
import ArticleMat from '../../components/Mat/ArticleMat/ArticleMat'
import PicMat from '../../components/Mat/PicMat/PicMat'
import TipHeader from "../../components/Header/TipHeader"
import {
    withRouter
} from "react-router-dom";

import common from "../../utils/common"
import baseConfig from "../../baseConfig.js"

import PicUpload from "../../components/upload/PicUpload";
import { getMatPicList }  from "../../reducers/mat/action"
import { getArticleList }  from "../../reducers/article/action"
import './Mat.less'

class Mat extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            id : '0',
            index : 0 ,
            cur_mp_id : 1,
            key : 1,
            isModalVisible : false,
            page : 1,
            pagenum : 10,
            total : 0,
            keyword : '',
            op_type : '',
        
        };
    }

    componentDidMount() {
        this.getArticleData();
        this.setState({total : this.props.article.total});
        this.getPicData();
    }

    getPicData(){
        var picListParam = {
            mp_id : '',
            page : this.state.page,
            pagenum : this.state.pagenum,
            keyword : this.state.keyword,
            op_type : this.state.op_type
        }
        this.props.getMatPicList(picListParam);
    }

    getArticleData(){
        var articleParam = {
            mp_id : '',
            page : this.state.page,
            pagenum : this.state.pagenum,
            keyword : this.state.keyword,
            op_type : this.state.op_type
        }
        this.props.getArticleList(articleParam)
    }
    
    // handleChangePublic(value , opt){
    //     this.setState({
    //         index : value,
    //         cur_mp_id : opt.cur_mp_id
    //     })
    // }
    
    onSearch(value){
        this.setState({keyword : value} , ()=>{
            var key = this.state.key;
            if(key == "1"){
                this.getArticleData()
            }else if(key == "2"){
                this.getPicData()
            }
        })
    }

    switchTab(activeKey){
        this.setState({
            key : activeKey ,
            page : 1, 
        }, ()=>{
            switch(activeKey){
                case "1" : 
                    this.getArticleData();
                    this.setState({total : this.props.article.total})
                break;
                case "2" :
                    this.getPicData();
                    this.setState({total : this.props.pic.total})
                    break;
            }
        });
    }

    onChangePage(page, pageSize){
        this.setState({
            page : page
        } , ()=>{
            var key = this.state.key;
            if(key == "1"){
                this.getArticleData()
            }else if(key == "2"){
                this.getPicData()
            }
        })
    }

    onPicOk(){
        var key = this.state.key;
        if(key == "1"){
            this.getArticleData()
        }else if(key == "2"){
            this.getPicData()
        }
        this.setState({isModalVisible : false})
    }

    onPicCancel(){
        this.setState({isModalVisible : false})
    }

    delPic(id){
        this.getPicData();
        this.setState({total : this.props.pic.total})
    }

    delArticle(){
        this.getArticleData();
        this.setState({total : this.props.article.total})
    }

    eidtComplete(){
        this.getPicData();
        this.setState({total : this.props.pic.total})
    }

    render(){
        
        const { Search } = Input;
        const { TabPane } = Tabs;
        const Mheader = ({children})=>{
            return (
                <div className={"mTitle"}>{children}</div>
            )
        }
        var { history , article , pic} = this.props;
        const BtnMat = ()=>{
            var key = this.state.key
            if(key == 1){
                return (
                    <Button type="primary" onClick={() => {
                        history.push({pathname: '/newMat'})
                    }}>新建文章</Button>
                )
            }else{
                return (
                    <Button type="primary" onClick={() => {
                      this.setState({isModalVisible : true})
                    }}>新建图片</Button>
                )
            }
        }
        return (
            <>
              <div className="mat1">
                    <TipHeader title={"素材库"}></TipHeader>
                    <PublicContent>
                        <Mheader>
                            <div className={'line'}></div>
                            <Row align='middle' gutter={[20 , 0]}>
                                <Col span={6}>
                                    <Search
                                    placeholder="请输入关键词"
                                    allowClear
                                    enterButton="搜索"
                                    onSearch={this.onSearch.bind(this)}
                                    />
                                </Col>
                                <Col span={6}>
                                   <BtnMat key={this.state.key}></BtnMat>
                                </Col>
                            </Row>
                        </Mheader>
                        <PublicContent>
                            <div className="card-container">
                                <Tabs type="card" onChange={this.switchTab.bind(this)}>
                                    <TabPane tab="文章" key="1" >
                                        <div className={'ctab clear'}>
                                            {
                                                article.data.map((item , index)=>{
                                                    var date = common.dateFtt("yyyy-MM-dd hh:mm:ss" , new Date((parseInt(item.updated_at))*1000))
                                                    return (
                                                        <ArticleMat 
                                                            title={item.article_name}
                                                            img={ baseConfig.picUrl + item.article_cover }
                                                            key={index}
                                                            date={date}
                                                            id={item.article_id}
                                                            delArticle={this.delArticle.bind(this)}
                                                        >
                                                        </ArticleMat>  
                                                    )
                                                })
                                            }
                                        </div>
                                    </TabPane>
                                    <TabPane tab="图片" key="2" >
                                        <div className={'ctab  clear'}>
                                            {
                                                pic.data.map((item , index)=>{
                                                    var date = common.dateFtt("yyyy-MM-dd hh:mm:ss" , new Date((parseInt(item.updated_at))*1000))
                                                    return (
                                                        <PicMat  
                                                            title={item.file_name}
                                                            img={ baseConfig.picUrl + item.local_url}
                                                            date={date}
                                                            key={index}
                                                            pic_id={item.image_id}
                                                            delPic = {this.delPic.bind(this)}
                                                            eidtComplete = {this.eidtComplete.bind(this)}
                                                        ></PicMat>
                                                    )
                                                })
                                            }
                                        </div>
                                    </TabPane>
                                   
                                </Tabs>
                                <Row align={'middle'} justify={"center"}  style={{marginTop : '30px' , paddingBottom : '20px'}}>
                                    <Col>
                                        <Pagination 
                                        defaultCurrent={this.state.page} 
                                        current={this.state.page} 
                                        pageSize={this.state.pagenum}
                                        total={this.state.total}  
                                        onChange={this.onChangePage.bind(this)}/>
                                    </Col>
                                </Row>
                            </div>
                        </PublicContent>
                    </PublicContent>
                    <PicUpload 
                        mp_id={this.state.cur_mp_id}
                        isModalVisible={this.state.isModalVisible} 
                        handleOk={()=>{this.onPicOk()}} 
                        handleCancel={this.onPicCancel.bind(this)}>
                    </PicUpload>
              </div>
            </>
        )
    }
} 

const stateToProps = (state)=>{
    return {
        pic : state.mat,
        article : state.article,
    }
}

const dispatchToProps =(dispatch)=>{
    return {
        getMatPicList(val){
            dispatch(getMatPicList(val))
        },   
        getArticleList(val){
            dispatch(getArticleList(val))
        },  

    }
}


export default connect(stateToProps, dispatchToProps)(withRouter(Mat));




