import React from 'react'

class UserAcount extends React.Component{
    
    render(){
        return (<> UserAcount </>)
    }
    
}


export default UserAcount;



