import React from 'react'
import './TipHeader.less'


class TipHeader extends React.Component{

    render(){
        var { title } = this.props;

        return (
            <>
               <div className="tipHeader">
                    <div className='tip-title'>
                        {title}
                    </div>
               </div>
            </>
        )
    }
} 


export default TipHeader;
















