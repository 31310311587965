import request from '../utils/request';

export function login(params) {
  return request('/user/login', {
        method: 'POST',
        data: params
  });
}








