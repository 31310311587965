import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'


import {persistStore,persistReducer} from "redux-persist"
import {PersistGate} from "redux-persist/es/integration/react"
import storage from 'redux-persist/es/storage'


import 'core-js/es'  
import 'react-app-polyfill/ie9'  
import 'react-app-polyfill/stable'


const config = {
    key:'root',
    storage
}

const middleware = [ thunk ];
if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger());
}


function configureStore(){
  let reducer = persistReducer(config,rootReducer);
  const store = createStore(reducer , applyMiddleware(...middleware))
  let persistor = persistStore(store)
  
  return {persistor,store}
}


const {persistor,store} = configureStore();


ReactDOM.render(
  
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App /> 
      </PersistGate>
    </Provider> 
  ,
  document.getElementById('root')
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
