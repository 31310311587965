import request from '../utils/request';

export function getServerPubs(params) {
  return request('/mp/list', {
        method: 'GET',
        params : params
  });
}



export function wxArticleUpload(params) {
  return request('/article/upload', {
        method: 'POST',
        data : params
  });
}









