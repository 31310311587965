import {login} from "../../services/login"

export const USER_ACTION = {
    LOGIN : 'LOGIN',
    UPDATE : 'UPDATE'
}

export const reqLogin = (param)=>{
    return (dispatch , getState)=> {
      
        return login(param).then((resp)=>{

            if(resp.data.code === 200){
                dispatch(updateUser(resp.data.data))
                dispatch(loginUser(resp.data))
                window.location.href = "/"

            }else{
                
                dispatch(loginUser(resp.data))
            }
        }).catch((err)=>{
            console.log(err)
        })
    }
}


export const updateUser = info =>({
    type : USER_ACTION.UPDATE,
    info : info
})

export const loginUser = info =>({
    type : USER_ACTION.LOGIN,
    info : info
})





