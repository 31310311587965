import { PUB_ACTION } from "./action"


const publics = (state = {} , action)=>{

    switch (action.type) {
        case PUB_ACTION.UPDATE:
              var info = action.info;
              if(info.code === 200){
                  var data = info.data
                  var obj = JSON.parse(JSON.stringify(Object.assign(state , data)))
                  return obj
              }else{
                  return state
              }
        default:
          return state
      }
}

export default publics;