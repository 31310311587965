import { Link } from 'react-router-dom';
import RouterConfig from "../../router/router"
import { Menu } from 'antd';
const { SubMenu } = Menu;

function MenuItem (props){
    // console.log(props.id)
    var index = props.id;
    var routes = RouterConfig[index].children;   
    return (
        <Menu theme="dark" defaultSelectedKeys={['0']} mode="inline">
            {
                routes.map((route , index)=>{
                    if(!route.children&&!route.hidden){
                        return (
                        <Menu.Item key={index} icon={route.icon}>
                            <Link to={{ pathname: route.path }}>
                                {route.name}
                            </Link>
                          </Menu.Item>
                        )
                    }else if(!route.hidden){
                        return (
                            <SubMenu key={index} icon={route.icon} title={route.name}>
                                {
                                    route.children.map((route , j)=>{
                                        if(!route.hidden){
                                            return (
                                                <Menu.Item key={ index + "" + j} >
                                                {/* <Menu.Item key={ index + "" + j} icon={route.icon}> */}
                                                     <Link to={{ pathname: route.path }}>
                                                        {route.name}
                                                    </Link>
                                                </Menu.Item>
                                            )
                                        }
                                    })
                                }
            
                            </SubMenu>
                        )
                    }
                })
            }
        </Menu>
    )
    
}

export default MenuItem
