import RouterConfig from "../../router/router"
import { Route , Redirect } from 'react-router-dom';

function MainRoutes (props){

    var index = props.id;
    var routes = RouterConfig[index].children;  

    var arr = [];
    for(var r of routes){
        if(r.children){
            for(var c of r.children){
                arr.push(c)
            }
        }
        arr.push(r)
    }

    return (
        <>
            {
                arr.map((route , index)=>{
                    if(route.component){
                        return (
                            <Route path={route.path} key={index} component={route.component}></Route>
                        )
                    }
                    // if(route.children){
                    //     route.children.map((route , index)=>{
                    //         if(route.component){
                    //             return (
                    //                 <Route path={route.path} key={"sub" + index} component={route.component}></Route>
                    //             )
                    //         }

                    //     })
                    // }
                })

            }
            <Redirect from='/' to='/home'></Redirect>
        </>
    )
}

export default MainRoutes
    