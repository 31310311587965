import React, { Component } from 'react';
import {Row , Col , Modal, message} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
    withRouter
} from "react-router-dom";

import  './ArticleMat.less'
import { articleDel } from "../../../services/matlist"


class ArticleMat extends Component {

  constructor(props){
    super(props)
  }

  componentDidMount() {
   
  }

  onMainPicClick(val){
    const {onMainPicClick} = this.props;
    if(onMainPicClick == undefined ||  !typeof onMainPicClick == "function"){
        return;
    }
    onMainPicClick(val)
  }


  goEditAritle(id){
    const { history } = this.props;
    history.push({
        pathname : '/editArt/' + id,
    })
  }

  delFile(){
    let {id , delArticle} = this.props;
    console.log(id)
    const { confirm } = Modal;
    confirm({
        icon: <ExclamationCircleOutlined />,
        content: <div>您确定删除吗？</div>,
        okText : "确认",
        cancelText : "取消",
        onOk() {
          // 调用接口；
          let param = {
            article_id : id
          }
          console.log('OK' , JSON.stringify(param));
          articleDel(param).then((resp)=>{
              if(resp.data.code === 200){
                message.success("操作成功！")
                if(delArticle){
                  delArticle(id)
                }
              }else{
                message.error("删除失败！")  
              }
          }).catch((err)=>{
              message.error("删除失败！")
          })
        },
        onCancel() {
          console.log('Cancel del');
        },
    });
  }

  render() {
    var { img , title , date , footer , id} = this.props
    const Footer=({aid})=>{
       
        if(footer == false){
            return (<></>)
        }else{
            return (
                <div className={"f3 clear"}>
                    <div className={"btna-1 lf"} onClick={()=>{this.goEditAritle(id)}}>{"编辑"}</div>
                    <div className={"btna-1 rf"} onClick={()=>{this.delFile()}}>删除</div>
                </div>
            )
        }
    }
    return (
            <div className={"articleBox"}>
                <div className={"f1"}>
                    <Row gutter={[10 , 0]} align={'middle'}>
                        <Col span={16}>
                            <div>{date}</div>
                        </Col>
                        {/* <Col span={8}>
                            <div>同步</div>
                        </Col> */}
                    </Row>
                </div>
                <div className={"f2"} onClick={()=>{this.onMainPicClick(id)}}>
                    <div className={"arct-title"}>{title}</div>
                    <div className={"pic"}
                        style={{backgroundImage : 'url(' + img +')'}}
                    >
                </div>
                </div>
                <Footer aid={id}></Footer>
            </div>
    );
  }
}


export default withRouter(ArticleMat);