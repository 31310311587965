import React from 'react'
import { Row  , Col , Input  , Card , Pagination  , Avatar , Button } from 'antd';
import TipHeader from "../../components/Header/TipHeader"
import PublicContent from "../../components/ContentCom/index"
import NoData  from '../../components/NoData/NoData'
import { connect } from 'react-redux'
import { getPubs } from "../../reducers/publics/action"
import {
    withRouter
} from "react-router-dom";

import  './Publics.less';

const { Search } = Input;
const { Meta } = Card;



class Publics extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            page : 1,
            pagenum : 2,
            keyword : '',
            total : 0
        }
    }

    componentDidMount(){
        const { publics } = this.props;
        if(publics != null){
            this.setState({total : publics.total} , ()=>{
                console.log(this.state.total)
            })
            this.getPubData();
        }
    }

    onSearch(value , event){
        this.setState({keyword : value} , ()=>{
            this.getPubData();
        })
    }

    getPubData(){
        var param = {
            keyword : this.state.keyword,
            page : this.state.page,
            pagenum : this.state.pagenum,
        }
        this.props.getPubs(param);
    }

    onChangePage(page, pageSize){
        console.log("----- page -----")
        console.log(page)
        console.log(pageSize)
        this.setState({page : page , pagenum : pageSize} , ()=>{
            this.getPubData();
        })
    }

    toPubArticle(id){
        console.log("mp_id : " + id)
        const { history } = this.props;
        history.push({
            pathname: '/pubArticle/' + id,
        })
    }

    cancelAuth(id){
        console.log(id);
    }

    render(){
        const { publics } = this.props;
        const PublicTitle = ()=>{
            return (
              <div className={"publicTitle"}>
                <div className={"pheader"}>
                  公众号列表
                </div>
                <Row gutter={[15, 0]}   align="middle">
                  <Col span={8}>
                    <div className={"search"}>
                      <Search
                          placeholder="搜索关键词"
                          allowClear
                          enterButton="搜索"
                          onSearch={this.onSearch.bind(this)}
                        />
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className={"search"}>
                        <Button type="primary" onClick={()=>{
                            window.open("https://weiying-api.mtadservice.com/mp/create")
                        }}>添加公众号</Button>
                    </div>
                  </Col>
                </Row>
              </div>
            )
        }

        if(publics != null && publics.data && publics.data.length && publics.data.length > 0){
            // console.log(publics.data)
            return (
                <>
                   <div className='pub1'>
                        <TipHeader title={"公众号管理"}></TipHeader>
                        <PublicContent>
                            <PublicTitle></PublicTitle>
                            <div className={'pcon'}>
                                <div className='clear'>
                                    {
                                        publics.data.map((item , index)=>{
                                            return (
                                                <div className='lf' key={index}>
                                                    <div className="site-card-border-less-wrapper">
                                                        <Card
                                                            style={{ width: 300 }}
                                                        >
                                                            <Meta
                                                            avatar={<Avatar src={item.mp_head_img} />}
                                                            title={item.mp_nick_name}
                                                            // description="This is the description"
                                                            ></Meta>
                                                            <div className={'tags'}>
                                                                {/* <Tag>Tag 1</Tag>
                                                                <Tag>Tag 2</Tag>
                                                                <Tag>Tag 3</Tag> */}
                                                            </div>
                                                            <div className={'hfooter'}>
                                                                <div className={'btn'} onClick={()=>{
                                                                    this.toPubArticle(item.mp_id)
                                                                }}>发布文章</div>
                                                                {/* <div className={'btn'}>更新信息</div> */}
                                                                <div className={'btn'} onClick={()=>{
                                                                    this.cancelAuth(item.mp_id)
                                                                }}>解除授权</div>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </div> 
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <Row align={'middle'} justify={"center"}  style={{marginTop : '20px' , paddingBottom : '20px'}}>
                                <Col>
                                    <Pagination 
                                    defaultCurrent={this.state.page} 
                                    current={this.state.page} 
                                    pageSize={this.state.pagenum}
                                    total={this.state.total}  
                                    onChange={this.onChangePage.bind(this)}/>
                                </Col>
                            </Row>
                        </PublicContent>
                   </div>
                </>
            )
        }else{
            return (
                    <>
                    <div className='pub1'>
                        <TipHeader title={"公众号管理"}></TipHeader>
                        <PublicContent>
                            <PublicTitle></PublicTitle>
                            <NoData></NoData>
                        </PublicContent>
                    </div>
                </>
            )
        }
    }
} 

const stateToProps = (state)=>{
    return {
        publics : state.publics,
    }
}

const dispatchToProps =(dispatch)=>{
    return {
        getPubs(val){
            dispatch(getPubs(val))
        },   
    }
}

export default connect(stateToProps, dispatchToProps)(withRouter(Publics));






