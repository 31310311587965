// import Home from '../pages/home/Home';
import Mat from '../pages/mat/Mat'
import Login from '../pages/login/Login'
// 账号列表
import Publics from '../pages/publics/Publics'

// import BigFish from '../pages/account/bigFish/index';
// import Fam from '../pages/account/families/index';
// import Headline from '../pages/account/headline/index';
// import Wind from '../pages/account/wind/index';

import SecurityLayout from "../layout/SecurityLayout"
import NewMat from "../pages/mat/newMat/NewMat"
import Vmat from "../pages/mat/vMat/Vmat"
import PubArticle from "../pages/publics/pubArticle/PubArticle"
import EditArticle from "../pages/mat/EditArticle/EditArticle"

import Master from "../pages/master/Matster"
import DataSafe from "../pages/manager/safe/DataSafe"
import UserAcount from "../pages/manager/useracount/UserAcount"


import {
    // DesktopOutlined,
    // PieChartOutlined,
    FileOutlined,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons';


const RouterConfig = [
    { 
        path: '/login', 
        icon : null,
        name : '登录',
        component: Login,
        extra : true,
        hidden : true
    },
    {
        path : '/',
        name : '首页',
        icon : null,
        component : SecurityLayout,
        hidden :true,
        children : [
            { 
                path: '/',
                name : "媒体管理",  
                component: null , 
                icon : <TeamOutlined /> ,
                hidden : false,
                children : [
                        {path: '/home',name : "公众号",component: Publics ,icon : <TeamOutlined /> ,hidden : false} ,
                        // {path: '/fish',name : "大鱼号",component: BigFish ,icon : <TeamOutlined /> ,hidden : false} ,
                        // {path: '/fam',name : "百家号",component: Fam, icon : <TeamOutlined /> ,hidden : false} ,
                        // {path: '/headline',name : "头条号",component: Headline,icon : <TeamOutlined /> ,hidden : false} ,
                        // {path: '/wind',name : "大风号",component: Wind ,icon : <TeamOutlined /> ,hidden : false} ,
                ]
            },
            { 
                path: '/mat', 
                name : "素材库" , 
                // component: Mat , 
                icon : <FileOutlined /> ,
                hidden : false,
                children : [
                    {path: '/mat',name : "图文素材",component: Mat ,icon : <TeamOutlined /> ,hidden : false} ,
                    {path: '/vmat',name : "视频素材",component: Vmat ,icon : <TeamOutlined /> ,hidden : false} ,
                    {path: '/master',name : "主站建设",component: Master ,icon : <TeamOutlined /> ,hidden : false} ,
                ]
            },
            { 
                path: '/newMat', 
                name : "新建文章" , 
                component: NewMat , 
                icon : null ,
                hidden : true,
                children : null
            },
            { 
                path: '/pubArticle/:id', 
                name : "公众号文章" , 
                component: PubArticle , 
                icon : null ,
                hidden : true,
                children : null
            },
            { 
                path: '/editArt/:id', 
                name : "编辑文章" , 
                component: EditArticle , 
                icon : null ,
                hidden : true,
                children : null
            },
            { 
                path: '/public', 
                name : "账号管理" , 
                icon : <UserOutlined /> ,
                hidden : false,
                children : [
                    {
                        path: '/datasafe',
                        name : "资料维护",  
                        component: DataSafe ,
                        hidden : false,
                    },
                    {
                        path: '/uaccount',
                        name : "账户安全",  
                        component: UserAcount , 
                        hidden : false,
                    },
                   
                ]
            },
        ]
    }
]



export default RouterConfig;
