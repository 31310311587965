import { matPicList } from "../../services/matlist"


export const MAT_ACTION = {
    UPDATE_PIC : 'UPDATE_PIC'
}


export const getMatPicList = (param)=>{
    return (dispatch , getState)=>{
        return matPicList(param).then((resp)=>{
            dispatch(updateMatPic(resp.data))
        }).catch((err)=>{
            console.log(err)
        })
    }
}


const updateMatPic = (info)=>({
    type : MAT_ACTION.UPDATE_PIC,
    info : info
})