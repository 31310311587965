import React from 'react'

import TipHeader from "../../../components/Header/TipHeader"
import PublicContent from "../../../components/ContentCom/index"
import Editor from "../../../components/Editor/Editor"
import PicSearchList from "../../../components/Mat/search/PicSearchList"
import {articleCreate} from "../../../services/matlist"
import baseConfig from "../../../baseConfig.js"

import { Form, Input, Button, Modal , Row , Col , message} from 'antd';
import './NewMat.less'

class NewMat extends React.Component {

    constructor(props){
        super(props)
        this.myRefUe = React.createRef();
        this.state = {
            isModalVisible : false,
            picIndex : 1,
            mainPic : '',
            cover : null,
        }
    }

    onFinish(obj){
        if(this.state.cover == null){
            message.error('主图不能为空！');   
            return;
        }
        const ue = document.getElementById("ed1");
        let iframe = ue.getElementsByTagName("iframe")[0];
        let ctx = iframe.contentWindow.document;
        let images = ctx.getElementsByClassName("art-pic")
        let img_list = [];
        for(let img of images){
            img_list.push(img.getAttribute("data-src"));
        }
        // 接口；//
        var html = this.myRefUe["current"].state.ueEditor.getContent();
        var param = {
            img_list : JSON.stringify(img_list),
            article_cover : this.state.cover,
            article_author : obj.user.author,
            article_name : obj.user.title,
            article_digest : obj.user.digest,
            article_content : html,
        }
        console.log(JSON.stringify(param))
        articleCreate(param).then((resp)=>{
            console.log(resp.data)
            if(resp.data.code == '200'){
                message.success('文章上传成功！'); 
            }else{
                message.error('文章提交失败！');  
            }
        }).catch((err)=>{
            console.log(err)
            message.error('文章提交失败！');  
        })
    }

    handleCancel(){
        this.setState({
            isModalVisible : false,
        })
    }

    goBack(){
        this.props.history.push({
            pathname: '/mat',
        })
    }

    getCover(val){
        this.setState({
            isModalVisible : true,
            picIndex : val
        })
    }

    onMainPicClick(val){
        console.log('new mat --- :' + val);
       
        if(this.state.picIndex == 1){
            this.setState({cover : val.local_url});
        }else{
            var html = `<img src="${baseConfig.picUrl + val.local_url}" data-src="${val.local_url}" class="art-pic" />`
            this.myRefUe["current"].state.ueEditor.execCommand( 'inserthtml' , html);
        }
        this.setState({
            isModalVisible : false,
        })
    }

    render() {

      const layout = {
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
      };

      const validateMessages = {
          required: '${label}不能为空!',
      };

      return (
        <div className="newMat">
            <TipHeader title={'新建文章'}></TipHeader>
            <PublicContent height="1000px">
                <Row>
                    <Col span={24}>
                        <div className={'pic-search-tool clear'}>
                            <div className={'pic-search-tool-btns lf'}>
                                <Button type={'primary'} onClick={()=>{this.goBack()}}>返回</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="clear ed">
                    <div className="editor-info lf"> 
                        <div className="ed-tool">
                            <div className="ed-img ed-btn" onClick={()=>{this.getCover(1)}}>封面图片</div>
                            <div className="ed-imgcover-show">
                                <div className="ed-imagecover" 
                                style={this.state.cover==null?{"backgroundImage" : ''}:{"backgroundImage" : 'url(' +baseConfig.picUrl +this.state.cover + ')'}}>
                                </div>
                            </div>
                            <div className="ed-img ed-btn" onClick={()=>{this.getCover(2)}}>插入图片</div>
                        </div>
                        <div className="ed-base-info">
                            <Form {...layout} name="nest-messages"  onFinish={this.onFinish.bind(this)} validateMessages={validateMessages}>
                                <Form.Item name={['user', 'author']} label="作者" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name={['user', 'title']} label="标题" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name={['user', 'digest']} label="摘要" rules={[{  required: true }]}>
                                    <Input.TextArea />
                                </Form.Item> 
                                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                    <Button type="primary" htmlType="submit">提交</Button>
                                </Form.Item>                       
                            </Form>
                        </div>
                    </div>
                    <div className="lf editor-insert" >
                        <Editor 
                            id='ed1' width={677} height={800} value={''} ref={this.myRefUe}
                        ></Editor>
                    </div>
                </div>
            </PublicContent>
            <Modal title={"图片素材"}
                width={900}
                okText={"确认"}
                cancelText={"取消"}
                visible={this.state.isModalVisible} 
                onCancel={this.handleCancel.bind(this)}
                closable={true}
                maskClosable={false}
                footer={null}
                >     
                <PicSearchList 
                    onMainPicClick={this.onMainPicClick.bind(this)}
                ></PicSearchList>
            </Modal>
        </div>
      );
    }
}

export default NewMat;

