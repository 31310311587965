import React from 'react'

class DataSafe extends React.Component{
    
    render(){
        return (<> 资料维护 </>)
    }
}


export default DataSafe;



