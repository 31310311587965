import { Result  } from 'antd'

export default ()=>{
    return (

        <div style={{margin : '0 auto'}}>
                    <Result
                    title="暂无数据"
                    extra={
                        <div></div>
                    }
            />
        </div>
    )

}