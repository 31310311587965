

import logo from "../../assets/images/logo.png"



function LogoFuc (){

    return (
        <div className="logo-title clear" style={{
            width : "180px" , height : '60px' 
            ,boxSizing : "border-box", display : 'flex' ,
            justifyContent : 'center',
            paddingTop : '15px'
        }}>
            <div style={{width : "100px", display : 'flex' , justifyContent : 'center'}}>
                <img src={logo} width={30} height={30}></img>
            </div>
            <div style={{fontSize : "16px" , width : "100px" , textAlign : 'center'}}>
                <div style={{ color : '#fff' , fontWeight : 700}}>微营平台</div>
            </div>
        </div>
    )

}




export default LogoFuc;