import request from '../utils/request';

export function logout(params) {
  return request('/user/logout', {
        method: 'POST',
        data : params
  });
}








