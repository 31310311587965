import React from 'react';
import { Modal } from 'antd';
import PicOne from "./PicOne";

class PicUpload extends React.Component{

    constructor(props){
        super(props)
    }

    render(){
        var { isModalVisible } = this.props;
        return (
            <>
                <Modal title={"图片素材"}
                okText={"确认"}
                cancelText={"取消"}
                visible={isModalVisible} 
                onOk={this.props.handleOk} 
                onCancel={this.props.handleCancel}
                closable={true}
                maskClosable={false}
                footer={null}
                >     
                  <PicOne 
                        mp_id={this.props.mp_id} 
                        handComplete={this.props.handleOk}
                    >
                  </PicOne>
                </Modal>
            </>
        )
    }
}


export default PicUpload;


