import React from 'react'
import { Row  , Col } from 'antd';
import './AHeader.less'
import logo from "../../assets/images/logo.png"



class AHeader extends React.Component{

    render(){
        return (
            <>
                <div className={'aheader'}>
                    <div className={'content'}>
                        <Row align={'middle'}>
                            <Col span={6}>
                                <img  src={logo} className={"logo"}/>
                            </Col>
                            <Col span={6}>
                            </Col>
                            <Col span={6}></Col>
                            <Col span={6}>
                                <div className='title'>微营平台</div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
} 





export default AHeader;
















