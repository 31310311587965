import  './index.less'


const PublicContent = (props)=> {

    const {children , height } = props
    return (
      <div className={"publiccontent"} style={{minHeight : height?height:'auto'}}>
      {children}
    </div>
    );
}
// const PublicContent = ({children})=> {

//     const {children , height } = this.props
//     return (
//       <div className={"publiccontent"} style={{height : height}}>
//       {children}
//     </div>
//     );
// }

export default PublicContent;