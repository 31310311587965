import React from 'react';
import { connect } from 'react-redux'
import {Redirect} from 'react-router-dom'
import { stringify } from 'querystring';
import BaseLayout from "./BaseLayout"
// import {getPubs} from "../reducers/publics/action"



class SecurityLayout extends React.Component {


  componentDidMount() {
      // var { getPubs }  = this.props;
      // getPubs({page : 1 , pagenum : 2 , keyword : ''});
  }

  render() {

    const { user } = this.props; 

    // 你可以把它替换成你自己的登录认证规则（比如判断 token 是否存在）
    const isLogin = user && user.user_id!=null;
  
    const queryString = stringify({
        redirect: window.location.href,
    });
    
    if (!isLogin && window.location.pathname !== '/login') {
        return <Redirect to={`/login?${queryString}`} />;
    }

    return (<BaseLayout />);
  }
}


const stateToProps = (state)=>{
  return {
      user : state.user,
  }
}

// const dispatchToProps =(dispatch)=>{
//   return {
//     getPubs(val){
//         dispatch(getPubs(val))
//     }
//   }
// }



export default connect(stateToProps)(SecurityLayout);
// export default connect(stateToProps, dispatchToProps)(SecurityLayout);
