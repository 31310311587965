import { MAT_ACTION } from "./action"


const mat = (state = { data : [] , total : 0 } , action)=>{
    switch (action.type) {
        case MAT_ACTION.UPDATE_PIC:
              var info = action.info;
              if(info.code === 200){
                    var data = info.data
                    var obj = JSON.parse(JSON.stringify(Object.assign(state , data)))
                    return obj
              }else{
                    return state
              }
        default:
          return state;
      }
}

export default mat;