import React from 'react'

import Editor from "../../../components/Editor/Editor";
import {articleInfo , articleUpdate} from "../../../services/matlist";
import TipHeader from "../../../components/Header/TipHeader";
import PublicContent from "../../../components/ContentCom/index";
import { Form, Input, Button, Modal , Row , Col , message} from 'antd';
import PicSearchList from "../../../components/Mat/search/PicSearchList";
import { withRouter } from "react-router-dom";
import baseConfig from "../../../baseConfig.js"


import './EditArticle.less';
import '../newMat/NewMat.less';

class EditArticle extends React.Component {

    constructor(props){
        super(props)
        this.myRefUe = React.createRef();
        this.state = {
            aid : 0,
            isModalVisible : false,
            picIndex : 1,
           
            article_name : 'name',
            article_cover : null,
            article_digest : 'digest',
            article_author : 'author',
            article_content : 'content',
            article_author : 'author',
        }
    }

    componentDidMount(){
        let id = this.props.match.params.id;
        this.setState({
            aid : id
        },()=>{
            console.log(this.state.aid)
            this.getData();
        })
    }

    getData(){
        articleInfo({article_id : this.state.aid}).then((resp)=>{
            console.log("article -------------- :")
            console.log(resp.data);
            if(resp.data.code === 200){
                let data = resp.data.data;
                this.setState({
                    article_id : data.article_id,
                    article_name : data.article_name,
                    article_cover : data.article_cover,
                    article_digest : data.article_digest,
                    article_content : data.article_content,
                    article_author : data.article_author
                })
                let ue = this.myRefUe["current"].state.ueEditor;
                ue.ready(()=> {
                    ue.setContent(data.article_content);
                });
            }else{
                message.error("获取内容失败！")
            }
        }).catch((err)=>{
            console.log(err)
            message.error("获取内容失败！")
        })
    }

    onFinish(obj){
        if(this.state.article_cover == null){
            message.error('主图不能为空！');   
            return;
        }
        if((this.state.article_author).trim() == ''){
            message.error('作者不能为空！');  
            return
        }
        if((this.state.article_name).trim() == ''){
            message.error('文章标题不能为空！');  
            return
        }
        if((this.state.article_digest).trim() == ''){
            message.error('详情不能为空！');  
            return
        }

        const ue = document.getElementById("ed1");
        let iframe = ue.getElementsByTagName("iframe")[0];
        let ctx = iframe.contentWindow.document;
        let images = ctx.getElementsByClassName("art-pic")
        let img_list = [];
        for(let img of images){
            img_list.push(img.getAttribute("data-src"));
        }
        // 接口；//
        var html = this.myRefUe["current"].state.ueEditor.getContent();

        var param = {
            article_id : this.state.article_id,
            img_list : JSON.stringify(img_list),
            article_cover : this.state.article_cover,
            article_author : this.state.article_author,
            article_name : this.state.article_name,
            article_digest : this.state.article_digest,
            article_content : html
        }
        
        console.log(JSON.stringify(param))
        articleUpdate(param).then((resp)=>{
            console.log(resp.data)
            if(resp.data.code == '200'){
                message.success('文章上传成功！'); 
                this.goBack();
            }else{
                message.error('文章提交失败！');  
            }
        }).catch((err)=>{
            console.log(err)
            message.error('文章提交失败！');  
        })
    }

    handleCancel(){
        this.setState({
            isModalVisible : false,
        })
    }

    goBack(){
        this.props.history.push({
            pathname: '/mat',
        })
    }

    getCover(val){
        this.setState({
            isModalVisible : true,
            picIndex : val
        })
    }

    onMainPicClick(val){
        console.log('new mat --- :' + val);
       
        if(this.state.picIndex == 1){
            this.setState({article_cover : val.local_url});
        }else{
            var html = `<img src="${baseConfig.picUrl + val.local_url}" data-src="${val.local_url}" class="art-pic" />`
            this.myRefUe["current"].state.ueEditor.execCommand( 'inserthtml' , html);
        }
        this.setState({
            isModalVisible : false,
        })
    }

    render() {

      const layout = {
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
      };

      const validateMessages = {
          required: '${label}不能为空!',
      };

      return (
        <div className="newMat">
            <TipHeader title={'编辑文章'}></TipHeader>
            <PublicContent height="1000px">
                <Row>
                    <Col span={24}>
                        <div className={'pic-search-tool clear'}>
                            <div className={'pic-search-tool-btns lf'}>
                                <Button type={'primary'} onClick={()=>{this.goBack()}}>返回</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="clear ed">
                    <div className="editor-info lf"> 
                        <div className="ed-tool">
                            <div className="ed-img ed-btn" onClick={()=>{this.getCover(1)}}>封面图片</div>
                            <div className="ed-imgcover-show">
                                <div className="ed-imagecover" 
                                style={this.state.article_cover == null?{"backgroundImage" : ''}:{"backgroundImage" : 'url(' + baseConfig.picUrl + this.state.article_cover + ')'}}>
                                </div>
                            </div>
                            <div className="ed-img ed-btn" onClick={()=>{this.getCover(2)}}>插入图片</div>
                        </div>
                        <div className="ed-base-info">
                            <Form {...layout} name="nest-messages"  onFinish={this.onFinish.bind(this)} validateMessages={validateMessages}>
                                <Form.Item label="作者" rules={[{ required: true }]}>
                                    <Input value={this.state.article_author} onChange={(e)=>{
                                        this.setState({
                                            article_author : e.target.value
                                        })
                                    }} />
                                </Form.Item>
                                <Form.Item label="标题" rules={[{ required: true }]}>
                                    <Input value={this.state.article_name}  onChange={(e)=>{
                                        this.setState({
                                            article_name : e.target.value
                                        })
                                    }} />
                                </Form.Item>
                                <Form.Item label="摘要" rules={[{  required: true }]}>
                                    <Input.TextArea value={this.state.article_digest} onChange={(e)=>{
                                        this.setState({
                                            article_digest : e.target.value
                                        })
                                    }} />
                                </Form.Item> 
                                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                    <Button type="primary" htmlType="submit">提交</Button>
                                </Form.Item>                       
                            </Form>
                        </div>
                    </div>
                    <div className="lf editor-insert" >
                        <Editor 
                            id='ed1' width={677} height={800}  ref={this.myRefUe}
                        ></Editor>
                    </div>
                </div>
            </PublicContent>
            <Modal title={"图片素材"}
                width={900}
                okText={"确认"}
                cancelText={"取消"}
                visible={this.state.isModalVisible} 
                onCancel={this.handleCancel.bind(this)}
                closable={true}
                maskClosable={false}
                footer={null}
                >     
                <PicSearchList 
                    onMainPicClick={this.onMainPicClick.bind(this)}
                ></PicSearchList>
            </Modal>
        </div>
      );
    }
}

export default withRouter(EditArticle);

