import React from 'react';
import toolbars from "./toolbar"

export default class Editor extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: this.props.id || null,
            ueEditor: null,
        }
    }

    componentDidMount() {
        var UE = window.UE;
        let { id } = this.state;
        if (id) {
            try {
                UE.delEditor(id);
            } catch (error) { }

            // let value = this.props.value ? this.props.value : '<p></p>';
            let ueEditor = UE.getEditor(id, {
                toolbars : toolbars,
                autoHeightEnabled: true,
                autoFloatEnabled: false,
                zIndex  :  1,
                //字体大小
                'fontsize': [10, 11, 12, 14, 16, 18, 20, 24, 36, 48],
                // 上传图片时后端提供的接口；
                serverUrl: false,
                // 保存的路径；
                imageUrl : false,
                enableAutoSave: false,
                autoHeightEnabled: false,
                initialFrameHeight: this.props.height,
                initialFrameWidth: '100%',
            });
            this.setState({ ueEditor });
            //判断有没有默认值
            ueEditor.ready((ueditr) => {
                // ueEditor.setHeight(this.props.height)
                // let val = value;
                // ueEditor.setContent(val);
                
            });
            //将文本回调回去
            ueEditor.addListener('selectionchange', (type) => {
                //console.log(ueEditor.getContent())
                // this.props.callback(ueEditor.getContent());
            });
            //清空富文本内容
            //this.refs.ueditor.changeContent("");
        }
    }

    inserterImage(val){
    
    }


    render() {
        let { id } = this.state;
        let { width  , height} = this.props;
        return (<div id="ue-ed">
            <textarea id={id} style={{ width }}></textarea>
        </div>)
    }
}