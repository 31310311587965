import React from 'react'
import { Layout } from 'antd';
import MenuItem from "../components/MenuRender/MenuRender"
import MainRoutes from "../components/MainRoutes/MainRoutes"
import MainHeader from "../components/Header/MainHeader"
import LogoFuc from "../components/Logo/Logo"
import "./BaseLayout.less"




const { Header, Content, Footer, Sider } = Layout;


class BaseLayout extends React.Component {

    
    constructor(props){
        super(props)
    }

    state = {
        collapsed: false,
        marginLeft : 200,
        clz : 1
    };
  
    onCollapse = collapsed => {
      console.log(collapsed);
      this.setState({ collapsed });
      if(this.state.collapsed){
          this.setState({
            marginLeft : 200,
            clz : 1
          })
      }else{
          this.setState({
            marginLeft : 80,
            clz : 2
          })
      }
    };
  
    render() {
      const { collapsed } = this.state;
      return (
        <Layout style={{ minHeight: '100vh' }}>
          <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}  style={{
              overflowY : 'auto',
              overflowX : 'hidden',
              height: '100vh',
              position: 'fixed',
              zIndex: 1000,
              left: 0
            }}>
            <LogoFuc></LogoFuc>
            <MenuItem id={1}></MenuItem>
          </Sider>
          <MainHeader clz={this.state.clz} ></MainHeader>
          <Content className={this.state.clz==1?'maincontent1':'maincontent2'}>
            <div className='coninner' >
                <MainRoutes id={1}></MainRoutes>
            </div>
          </Content>
        </Layout>
      );
    }
}




export default BaseLayout;
















