/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */

import md5 from "js-md5";
import qs from "qs"
import common from './common'
import axios from "axios";

import MtConfig from '../baseConfig.js'


const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};



/**
 * 配置request请求时的默认参数
 */

let prefix = ''

if(MtConfig.mode == 'dev'){
  prefix = ''
}else{
  prefix = MtConfig.server
}

// url/put/1
const request_server = axios.create({
    baseURL : prefix,
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
})

request_server.interceptors.request.use((config) => {
    mothod_md5(config)
    var filePost = config.headers["Content-Type"];//文件上传；
    if(config.method == "post" &&  filePost != "multipart/form-data"){
      config.data = qs.stringify({ ...config.data });
    }
    if(config.method == "put"){
      config.data = qs.stringify({ ...config.data });
    }
    const token = common.getToken();
    if (token) {
        config.headers['UAUTHORIZATION'] = token;
    }
    return config;
}, (error) => {
    console.log(error)
    return Promise.reject(error);
});


request_server.interceptors.response.use(function (response) {
  var data = response.data
  if(data.code == '503503'){
      localStorage.clear();
      window.location.href = '/#/login'
      return;
  }
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

// time 参数也在排序范围内；
function mothod_md5(config) {

  // A6-E7-C8-54-CG-RN
  const sign_s = 'A6-E7-C8-54-CG-RN';
  const time = Date.parse(new Date()) / 1000;
  let sign = null;

  if (config.method == 'get') {
  
      let data = '';
      if(config.params){
          // config.params.time = time;
          var obj = {}
          for(var key in config.params){
              obj[key] = config.params[key]
          }
          obj.time = time;
          var attrs = getSortArr(obj);
          for(var i = 0 ;i < attrs.length; i++){
              var item = attrs[i]   
              if((obj[item])+"" != ""){
                  data += `&${item}|${obj[item]}`
              }
          }
      }else{
          data = `&time|${time}`
      }
    
      sign = md5(`${sign_s}${data}`);
      config.params = {
          ...config.params,
          sign: sign,
          time : time,
      };
  }

  if (config.method == 'post') {
      let url = config.url.split("?")
      let data = '';

      if (url[1]) {
          url = `&${url[1]}`; // key=val&key2=val2
          var obj = getUrlObj(url)
          obj.time = time;
      
          var attrs = getSortArr(obj);
          for(var i = 0 ;i < attrs.length; i++){
              var item = attrs[i]   
              if((obj[item])+"" != ""){
                  data += `&${item}|${obj[item]}`
              }
          }

          sign = md5(`${sign_s}${data}`);
          config.url = `${config.url}&sign=${sign}&time=${time}`;
      
      } else {

          var obj = {}
          obj.time = time;
          var attrs = getSortArr(obj)
          for(var i = 0 ;i < attrs.length; i++){
              var item = attrs[i]   
              if((obj[item])+"" != ""){
                  data += `&${item}|${obj[item]}`
              }
          }
        
          sign = md5(`${sign_s}${data}`);
          config.url = `${config.url.split("?")[0]}?sign=${sign}&time=${time}`;
      }
  }
}

function getSortArr(obj){
  var arr = []
  for(var key in obj){
      arr.push(key)
  }
  arr.sort();
  return arr;
}

function getUrlObj(strs){
  var obj = {}
  var strArr = strs.split("&")
  for(var i = 0 ; i<strArr.length ; i++){
      var item = strArr[i]
      var str = item.split("=")
      var key = str[0]
      var val = str[1]?str[1]:'';
      obj[key] = val
  }
  return obj;
}

function request(url , params){
  return request_server({url : url , ...params})
}

export default request;


