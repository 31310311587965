import React, { Component } from 'react';
import { Modal , Input , message} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './PicMat.less'

import { imageDel , imageUpdate} from "../../../services/matlist.js"


class PicMat extends Component {

  constructor(props){
    super(props)
    this.state = {
        isModalVisible : false,
        title : '',
        pic_id : this.props.pic_id
    }
  }

  componentDidMount() {
    
  }

  handleOk(){
    let title = this.state.title;
    if(title.trim() == ''){
        message.error("请输入标题！")
        return
    }
    let id = this.state.pic_id;
    const {eidtComplete} = this.props;
    let param = {
      image_id : id,
      image_name : title
    }
    imageUpdate(param).then((resp)=>{
        if(resp.data.code === 200){
            message.success("操作成功！")
            if(eidtComplete){
              eidtComplete(param)
            }
        }else{
            message.error("操作失败！")
        }
        this.setState({isModalVisible : false})
    }).catch((err)=>{
        message.error("操作失败！")
        this.setState({isModalVisible : false})
    })
    
  }

  handleCancel(){
    this.setState({isModalVisible : false})
  }

  onMainPicClick(val){
    const {onMainPicClick} = this.props;
    if(onMainPicClick == undefined ||  !typeof onMainPicClick == "function"){
        return;
    }
    onMainPicClick(val)
  }

  editFile(){
    this.setState({isModalVisible : true})
  }

  delFile(){
    let id = this.state.pic_id;
    const { confirm } = Modal;
    const { delPic } = this.props;
  
    confirm({
        icon: <ExclamationCircleOutlined />,
        content: <div>您确定删除吗？</div>,
        okText : "确认",
        cancelText : "取消",
        onOk() {
            console.log('OK : 1');
            // 调用接口；
            imageDel({image_id : id}).then((resp)=>{
                console.log(resp)
                if(resp.data.code === 200){
                    message.success("删除成功！")
                    if(delPic){
                      delPic(id);
                    }
                }else{
                    message.error("删除失败！")
                }
            }).catch((err)=>{ 
                message.error("删除失败！")
                console.log(err)
            });
        },
        onCancel() {
          console.log('Cancel del');
        },
    });
  }

  destroyAll() {
    Modal.destroyAll();
  }

  render() {
    const { footer } = this.props
    const FooterEl =  ()=>{
      if(footer == false){
          return (<></>)
      }else{
          return (
            <div className={'f3'}>
                <div className={'btn-pic'} onClick={()=>{this.editFile()}}>编辑</div>
                <div className={'btn-pic'} onClick={()=>{this.delFile()}}>删除</div>
            </div>
          )
      }
    }

    return (
        <div>

            <div className={'picArticleBox'}>
                <div className={'f2'} onClick={()=>{
                  this.onMainPicClick(this.props.pic_id)
                }}>
                    <div className={'clear'}>
                      <div className={'pic-title lf'}>{this.props.title}</div>
                      <div className={'pic-update-time lf'}>{this.props.date}</div>
                    </div>
                    <div className={'pic-m-image'}
                    style={{backgroundImage : 'url(' + this.props.img + ')'}}
                    >
                    </div>
                </div>
                <FooterEl></FooterEl>
            </div>
            <Modal title={"图片素材"}
                okText={"确认"}
                cancelText={"取消"}
                visible={this.state.isModalVisible} 
                onOk={()=>{this.handleOk()}} 
                onCancel={()=>{this.handleCancel()}}
                closable={true}
                maskClosable={false}
            >     
                <div className="pic-one-label" style={{marginBottom :'20px'}}>
                    标题
                </div>

                 <Input placeholder="标题" value={this.state.title} onChange={(e)=>{
                    let value = e.target.value;
                    this.setState(()=>({title:value }))
                  }}/> 
            </Modal>
        </div>
    );
  }
}

export default PicMat;
