import React from 'react';
import { Upload, Button, message , Input} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { matPicUpload } from "../../services/upload";
import axios from "axios"


import "./PicOne.less"

class PicOne extends React.Component {
  state = {
      fileList: [],
      uploading: false,
      title : ''
  };

  handleUpload = () => {
      const { fileList } = this.state;
      this.setState({uploading: true});
      if(this.state.title == ''){
          message.success('请填写文件标题！');
          return
      }
      // console.log(fileList[0])
      // let param = {
      //     file_name : this.state.title,
      //     FILE : fileList[0],
      //     mp_id : this.props.mp_id
      // }
      // 创建FormData对象
      let param = new FormData()
      param.append('FILE', fileList[0]);
      param.append('mp_id', this.props.mp_id);
      param.append('file_name', this.state.title,);

      matPicUpload(param).then((resp)=>{
          console.log(resp.data)
          if(resp.data.code == 200){
              message.success('文件上传成功！'); 
          }else{
              message.error('文件上传失败！');   
          }
          this.setState({
              uploading: false,
              title : '',
              file : null,
              fileList: []
          });
          this.props.handComplete();
      }).catch((err)=>{
          message.error('文件上传失败！');
          this.setState({
              uploading: false,
              title : '',
              file : null,
              fileList: []
          });
          this.props.handComplete();
      })
  };

  render() {
    const { uploading, fileList } = this.state;
    const props = {
      onRemove: file => {
        this.setState(state => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
                fileList: newFileList,
            };
        });
      },
      beforeUpload: file => {
          console.log(file)
          if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/gif') {
              this.setState({fileList: [file]});
              return false;
          }else{
              message.error(`${file.name} 不是文件图片`);
              return false;
          }
      },
      fileList,
    };

    return (
      <>
        <div className="pic-one-label">
            标题
        </div>
        <div className="pic-one">
            <Input placeholder="标题" value={this.state.title} onChange={(e)=>{
               let value = e.target.value;
               this.setState(()=>({title:value }))
            }}/>
        </div>
        <Upload {...props}  maxCount={1}>
          <Button icon={<UploadOutlined />}>选择图片</Button>
        </Upload>
        <Button
          type="primary"
          onClick={this.handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
            {uploading ? '上传中' : '开始上传'}
        </Button>
      </>
    );
  }
}


export default PicOne;