import React from 'react'
import './Login.less'
import AHeader from "../../components/Header/AHeader"
import BaseFooter from "../../components/BaseFooter/BaseFooter"
import { Form, Input, Button } from 'antd';

// import SecurityLayout from '../../layout/SecurityLayout'

import { connect } from 'react-redux'
import {reqLogin} from '../../reducers/user/action'


class Login extends React.Component{


    onFinish(value){
       
       this.props.reqLogin(value)
        
    }

    onFinishFailed(errorInfo){

        console.log(JSON.stringify(errorInfo))
    }

    render(){

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 10 }
        };

        const tailLayout = {
            wrapperCol: { offset: 8, span: 8 }
        };

        let { user } = this.props
        return (
            <>
                <AHeader></AHeader> 
                <div className="login">
                    <div className="content">
                        <div className='login-form'>
                            {/* {JSON.stringify(user)} */}
                            <Form
                                {...layout}
                                name="basic"
                                initialValues={{ remember: true }}
                                onFinish={this.onFinish.bind(this)}
                                onFinishFailed={this.onFinishFailed.bind(this)}
                                >
                                <Form.Item
                                    label="用户名"
                                    name="account"
                                    rules={[{ required: true, message: '请填写用户名' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="密码"
                                    name="password"
                                    rules={[{ required: true, message: '请填写密码' }]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button type="primary" htmlType="submit">
                                        确认
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>

                    </div>
                </div>
                <BaseFooter></BaseFooter>
            </>
        )
    }
} 



const stateToProps = (state)=>{
    return {
        user : state.user,
    }
}

const dispatchToProps =(dispatch)=>{
    return {
        reqLogin(val){
            dispatch(reqLogin(val))
        },   
    }
}





export default connect(stateToProps, dispatchToProps)(Login);