import request from '../utils/request';

export function matPicList(params) {
  return request('/image/list', {
        method: 'GET',
        params: params
  });
}

export function mediaList(params) {
  return request('/article/media-list', {
        method: 'GET',
        params: params
  });
}

export function articleList(params) {
  return request('/article/list', {
        method: 'GET',
        params: params
  });
}

export function articleCreate(params) {
  return request('/article/create', {
        method: 'POST',
        data: params
  });
}

// 图片删除功能接口；
export function imageDel(params) {
  return request('/image/del', {
        method: 'PUT',
        data: params,
  });
}

// 图片编辑
// /image/update
export function imageUpdate(params) {
  return request('/image/update', {
        method: 'PUT',
        data: params,
  });
}


// 文章删除功能接口；
export function articleDel(params) {
  return request('/article/del', {
        method: 'PUT',
        data: params,
  });
}

//  文章详情 /article/info
export function articleInfo(params) {
  return request('/article/info', {
        method: 'GET',
        params: params,
  });
}

// 文章更新接口 /article/update
export function articleUpdate(params) {
  return request('/article/update', {
        method: 'PUT',
        data: params,
  });
}











