import React from 'react'
import TipHeader from "../../../components/Header/TipHeader"
import PublicContent from "../../../components/ContentCom/index"
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import ArticleList from "../../../components/Mat/search/ArticleList"
import { Button, Card , message, Modal} from 'antd';
import { CaretUpOutlined , DeleteOutlined } from '@ant-design/icons';
import baseConfig from "../../../baseConfig.js"
import {wxArticleUpload} from "../../../services/pub.js"


import "./PubArticle.less"

class PubArticle extends React.Component{

    constructor(props){
        super(props)
        this.state={
            pub : {
                mp_nick_name : '',
                mp_head_img : '',
                isModalVisible : false,
                mp_id : 0
            },
            list : [],
            op_type : '1001',
            flag : true
        }
    }

    componentDidMount(){
       
        const publics = this.props.publics;
        let id = this.props.match.params.id;
        for(let pub of publics.data){
            if(pub.mp_id == id){
                this.setState({
                    pub : pub
                } , ()=>{
                    console.log(this.state.pub)
                })
            }
        }
    }

    handleCancel(){
        this.setState({
            isModalVisible : false
        })
    }

    onMainPicClick(val){
        console.log(val)
        const { article } = this.props;
        for(let a of article){
            if(a.article_id == val){
                this.setState({
                    list : [...this.state.list , a]
                })
                console.log(a)
            }
        }
        this.setState({isModalVisible : false})
    }

    selectArt(){
        this.setState({isModalVisible :true})
    }

    synchronizationWX(){
        console.log("upload")
        let ids = [];
        for(let l of this.state.list){
            ids.push(l.article_id);
        };
        let param = {
            mp_id : this.state.pub.mp_id,
            op_type : this.state.op_type,
            article_id : JSON.stringify(ids)
        };
        if(this.state.flag){
            this.setState({flag : false})
            wxArticleUpload(param).then((resp)=>{
                let data = resp.data
                if(data.code === 200){
                    message.success("微信文章同步成功！");
                    this.setState({
                        flag : true,
                        list : []
                    })
                }else{
                    message.error("微信文章同步失败！");
                    this.setState({
                        flag : true
                    })
                }
            }).catch((err)=>{
                message.error("微信文章同步失败！");
                this.setState({
                    flag : true
                });
            })
        }
    }
    upList(index){
        let arr = this.state.list;
        if(index !== 0){
            let cur = arr[index]
            let pre = arr[index-1]
            arr[index] = pre
            arr[index-1] = cur
        }
        this.setState({
            list : arr
        })
    }
    delListAricle(index){
        let arr = this.state.list;
        arr.splice(index , 1)
        this.setState({
            list : arr
        })
    }

    back(){
        const { history } = this.props;
        history.go(-1);
    }

    render(){

        const { Meta } = Card;
        const { pub , list } = this.state;
        
        const ArtPubLis = ()=>{
            if(list.length <= 0){
                return (<></>);
            }
            return (<>
                {
                    list.map((item , index)=>{
                        if(index == 0 ){
                            return (
                                <div className={'pub-art-box clear'} key={index}>
                                    <div className={'lf pub-art-lf pub-first-main clear'}
                                        style={{backgroundImage : 'url(' + baseConfig.picUrl + item.article_cover +')'}}
                                    >
                                    {item.article_digest}
                                    </div>
                                    <div className={'rf pub-art-rf'}>
                                        <Button type="primary" shape="circle" icon={<CaretUpOutlined/>} onClick={()=>{this.upList(index)}} />
                                        <Button type="primary" shape="circle" icon={<DeleteOutlined/>}  onClick={()=>{this.delListAricle(index)}} />
                                    </div>
                                </div>
                            )
                        }else{
                            return (
                                <div className={'pub-art-box clear'}  key={index}>
                                    <div className={'lf pub-art-lf clear'}>
                                        <div className={'pub-art-digest lf'}> {item.article_digest} </div>
                                        <div className={'pub-art-rfbg rf'}
                                         style={{backgroundImage : 'url(' +baseConfig.picUrl + item.article_cover +')'}}
                                        ></div>
                                    </div>
                                    <div className={'rf pub-art-rf'}>
                                        <Button type="primary" shape="circle" icon={<CaretUpOutlined />} onClick={()=>{this.upList(index)}} />
                                        <Button type="primary" shape="circle" icon={<DeleteOutlined />}  onClick={()=>{this.delListAricle(index)}} />
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </>)
        }

        return (
            <>
                <div className={"pub-article"}>
                    <TipHeader title={'公众号文章'}></TipHeader>
                    <PublicContent height="900px">
                        <div className={'clear'}>
                            <div className='pub-art-main lf' >
                                <div>
                                    <Card style={{width:200 , height : 200 , padding: '10px' , overflow : 'hidden'}}
                                        cover={
                                            <div style={{ width: 120 , height : 120 , borderRadius : "50%" , overflow : 'hidden' , margin : '0 auto'}} >
                                                    <img
                                                        alt="example"
                                                        src={pub.mp_head_img}
                                                        style={{maxWidth : '100%'}}
                                                    />
                                            </div>
                                        }
                                    >
                                        <Meta title={pub.mp_nick_name} />
                                    </Card>
                                    <div className={'pub-art-select'}>
                                        <Button type="primary" onClick={()=>{this.selectArt()}}>选择文章</Button>
                                    </div>
                                    <div className={'pub-art-select'}>
                                        <Button type="primary" 
                                        disabled={this.state.list.length>0?false:true}
                                        onClick={()=>{this.synchronizationWX()}}>同步微信</Button>
                                    </div>
                                    <div className={'pub-art-select'}>
                                        <Button type="ghost" 
                                        onClick={()=>{this.back()}}>返回</Button>
                                    </div>
                                </div>
                            </div>
                            <div className={'pub-art-cont1 lf'}>
                                <div className={'pub-art-cont1-1'}>
                                    <ArtPubLis></ArtPubLis>
                                </div>
                            </div>
                        </div>
                    </PublicContent>

                    <Modal title={"文章列表"}
                        width={900}
                        okText={"确认"}
                        cancelText={"取消"}
                        visible={this.state.isModalVisible} 
                        onCancel={this.handleCancel.bind(this)}
                        closable={true}
                        maskClosable={false}
                        footer={null}
                        >     
                        <ArticleList 
                            onMainPicClick={this.onMainPicClick.bind(this)}
                        ></ArticleList>
                    </Modal>
                </div>
            </>
        )
    }
}

const stateToProps = (state)=>{
    return {
        publics : state.publics,
        article : state.article.data,
    }
}

const dispatchToProps =(dispatch)=>{
    return {
          
    }
}

export default connect(stateToProps)(withRouter(PubArticle));


