import React from 'react'

class Vmat extends React.Component{
    


    render(){
        return (<>
            video mat
        </>)
    }
}


export default  Vmat;