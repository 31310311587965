

/**
 * 在列表指定位置插入元素
 */
const addChildAt = function( arr , child, index) {
    arr.splice(index, 0, child);
}
 /**
 * 在列表最后插入元素
 */

const appendChild = function( arr , child) {
    addChildAt(arr, arr.length, child);
}
/**
 * 在列表前面插入元素
 */
const prependChild = function(arr , child) {
    addChildAt(arr , child, 0);
}
/**
 * 从列表中移除元素
 */
// const removeChild = function(arr , child , attr) {

//     for(var i = 0, len = arr.length; i < len; i++) {
//         if(attr){
//             if(childs[i].attr == child) {
//                 this.removeChildAt(i);
//                 break;
//             }
//         }else{
//             if(childs[i] == child) {
//                 this.removeChildAt(i);
//                 break;
//             }
//         }
//     }
// }
/**
 * 从列表中移除指定位置的元素
 */
const removeChildAt = function(arr , index) {
    var child = arr.splice(index, 1);
    return child;
}
/**
 * 移除所有元素
 */
const removeAll = function(arr) {
    arr.length = 0;
    arr = []
}

// 判断数组中是否有相同的元素

const hasEqualElInArr = function(arr  , val){
    var flag = false;
    for(var i = 0 ; i< arr.length ; i++ ){
    
        if(val == arr[i]){
            flag = true;
            break;
        }
    }

    return flag;
}

function clone (arr) {
    let arr1 = []
   
    for(var i = 0; i < arr.length ; i++){
        var item = arr[i]
        if(typeof(item) !== 'object') {
            arr1.push(item)
        } else {
        //根据遍历的对象新建一个相同类型的空对象
            let obj = item instanceof Array ? [] : {}
            for(var key in item){
                if(item.hasOwnProperty(key)){
                    obj[key] = item[key]
                }
            }
          
            arr1.push(obj)
            
        }
    }
    
    return arr1
}

function dateFtt(fmt,date){ 
    var o = { 
        "M+" : date.getMonth()+1,     //月份 
        "d+" : date.getDate(),     //日 
        "h+" : date.getHours(),     //小时 
        "m+" : date.getMinutes(),     //分 
        "s+" : date.getSeconds(),     //秒 
        "q+" : Math.floor((date.getMonth()+3)/3), //季度 
        "S" : date.getMilliseconds()    //毫秒 
    }; 
    if(/(y+)/.test(fmt)) 
        fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length)); 
    for(var k in o) {
        if(new RegExp("("+ k +")").test(fmt)) 
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length))); 
    }
    return fmt; 
}


async function getPromiseVal(pro){
    return await new Promise(function (resolve, reject) {
        pro.then((data)=>{
            resolve(data)
        }).catch((err)=>{
            reject(err)
        })
    })
} 

function splitUrlNoPort(str){
    // 处理  http://124.239.150.186:5564  带端口号的
    var strs = str.split(":")
    var url = strs[0] + ":" + strs[1]
    return url
}

function splitUrlHost(str){
    // 处理 
    // 124.239.150.186:5564  带端口号的
    var strs = str.split(":")
    var url = strs[0] 
    return url
}



// smart.xcastle.cn对应测试服务器：http://124.239.150.186:5564  
// chinatelecom.xcastle.cn对应测试服务器：http://124.239.150.1865557

// smart.xcastle.cn对应测试服务器：http://124.239.150.186:5564  
// chinatelecom.xcastle.cn对应测试服
var host = splitUrlHost((window.location.host));

console.log(host)

function getHost(){
    return  splitUrlHost((window.location.host));
}

function setToken ( token ){
    localStorage.setItem('token', token);
    
}

function getToken (){
    
    var token = localStorage.getItem('token');
    return token;
}

function getTodayStartDate(){
    var start = new Date(new Date(new Date().toLocaleDateString()).getTime());
    return start;
}

// 秒级的
function getTodayStartTime(){
    var date = getTodayStartDate();
    return parseInt(date.getTime()/1000);
}


//函数调用传参格式为 2018-6-6或者2018.6.6
//如：startUnix(2018-6-6) 返回的时间戳格式‘1528300799’

function startUnix($date) {
    return(new Date(Date.parse($date.replace(/-/g, "/")))).getTime() / 1000;
}

function endUnix($date) {
    return new Date().setTime(Date.parse($date.replace(/-/g, "/")) / 1000 + 24 * 60 * 60 - 1);
}

function add0(m){
    return m<10?'0'+m:m 
};

// 获取当前天的开始时间和结束时间
var getTodayDate = function(){
    var today = [];
    var todayDate = new Date();
    var y = todayDate.getFullYear();  
    var m = todayDate.getMonth() + 1;
    var d = todayDate.getDate();
    var s = y+'-'+add0(m)+'-'+add0(d)+' 00:00:00';//今日开始
    var e = y+'-'+add0(m)+'-'+add0(d)+' 23:59:59';//今日结束
    today.push(s);
    today.push(e);
    return(today);
};


function getBase64 (file , callback1 ,callback2) {
    return new Promise(function(resolve, reject) {
        if(callback1){callback1()}
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);

        reader.onload = function() {
            imgResult = reader.result;
        };
        reader.onerror = function(error) {
            reject(error);
            if(callback2){
                callback2()
            }
        };
        reader.onloadend = function() {
            resolve({image : imgResult , file : file , msg : "ok"});
            if(callback2){
                callback2()
            }
        };
    });
};

function isImage(fileName) {
    if (typeof fileName !== 'string') return;
    let name = fileName.toLowerCase();
    return name.endsWith('.png') || name.endsWith('.jpg')
};

function isFitFileSize(file , size){
    var isLt1000k = file.size / 1024 / 1024 < size
    if(!isLt1000k){
        return false;
    }
    return true;
}


// 判断身份证号码的方法；
function validateIdentity (idNo){
    // var regIdNo = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/; 
    var regIdNo =  /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/; 
    if(!regIdNo.test(idNo)){ 
        return false; 
    }
    return true;
}

// 判断手机号的方法；
function validatePhone (idNo){
    // var regIdNo = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/; 
    var regIdNo =  /^1[0-9][0-9]\d{8}$/; 
    if(!regIdNo.test(idNo)){ 
        return false; 
    }
    return true;
}



function uuid() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
}

const common  = {

    addChildAt,
    appendChild,
    prependChild,
    // removeChild,
    // removeChildAt,
    removeAll,
    clone,
    dateFtt,
    hasEqualElInArr,
    getPromiseVal,
    setToken,
    getToken,
    getTodayStartDate,
    getTodayDate,
    getBase64,
    isImage,
    isFitFileSize,
    validateIdentity,
    validatePhone,
    uuid,
    getTodayStartTime,
    getHost
}



export default common;