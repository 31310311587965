import React from 'react'

class Matster extends React.Component{
    


    render(){
        return (<>
            主站建设
        </>)
    }
}


export default Matster;


