import './App.less';
import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import RouterConfig from "./router/router.js"



function App() {
  return (
    <div className="App">
       <Router>
          <Switch>   
            {
              RouterConfig.map((route , index)=>{
                if(route.exact){
                      return (
                        <Route path={route.path} exact key={index} render={porps=>(<route.component {...porps}></route.component>)}></Route>
                      )
                  }else{
                      return (
                        <Route path={route.path} key={index} render={porps=>(<route.component {...porps}></route.component>)}></Route>
                      )
                  }
              })
            }
          </Switch>
        
      </Router>
    </div>
  );
}

export default App;
