import request from '../utils/request';

export function matPicUpload(params) {
  return request('/image/upload', {
        method: 'POST',
        data: params,
        headers: { 'Content-Type': 'multipart/form-data' },
  });
}







