import { combineReducers } from 'redux'
import user from './user/user.js'
import publics from "./publics/publics"
import mat from "./mat/mat"
import article from "./article/article"



export default combineReducers({
    user , publics , article , mat
})