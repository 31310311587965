import { USER_ACTION } from "./action"
import { message } from 'antd';
import common from "../../utils/common"

    
const user = (state = {user_id : null}, action) => {
    switch (action.type) {
      case USER_ACTION.LOGIN:
            var info = action.info;
            if(info.code === 200){
                  common.setToken(info.data.token)
                  var obj = JSON.parse(JSON.stringify(Object.assign(state , {token : info.data.token})))
                  return obj
            }else{
                  message.success('登录失敗！');
                  return {...state}
            }
      case USER_ACTION.UPDATE:
            var info = action.info;
            if(info){
                  var obj = JSON.parse(JSON.stringify(Object.assign(state , info.user_info)))
                  return obj
            }else{
                  return {...state}
            }
      default:
        return state
    }
}

export default user;