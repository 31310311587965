import { articleList } from "../../services/matlist"


export const ARTICLE_ACTION = {
    UPDATE_LIST : 'UPDATE_LIST_ARTICLE'
}


export const getArticleList = (param)=>{
    return (dispatch , getState)=>{
        return articleList(param).then((resp)=>{
            dispatch(updateArticleList(resp.data))
        }).catch((err)=>{
            console.log(err)
        })
    }
}


const updateArticleList = (info)=>({
    type : ARTICLE_ACTION.UPDATE_LIST,
    info : info
})