import React from 'react'
import './MainHeader.less'
import { connect } from 'react-redux'
import { Avatar , Menu, Dropdown , message} from 'antd';
import { UserOutlined , DownOutlined } from '@ant-design/icons';

import { logout } from "../../services/user"


class MainHeader extends React.Component{

    constructor(props){
        super(props)
    }


    logout(){
        
        console.log("------ 登出 ------- ");
        logout().then((resp)=>{
            let data = resp.data;
            if(data.code == "200"){
                localStorage.clear();
                window.location.href = '/#/login'
            }else{
                message.error("登出失败！")
            }
        }).catch((err)=>{
            console.log(err)
            message.error("登出失败！")
        })

    }

    render(){
    
        var { user , clz } = this.props;

        const menu = (
            <Menu>
              <Menu.Item>
                <div className={'mainheader-item'} onClick={()=>{this.logout()}}>
                    登出
                </div>
              </Menu.Item>
            </Menu>
        );
        return (
          
            <div className={clz == 1 ? 'mainHeader1' : 'mainHeader2'}>
                <Dropdown overlay={menu}>
                    <div className="main-header-user clear rf">
                        <div className="useravatar lf">
                            <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                        </div>
                        <div className='username lf'>{user.user_name}</div>
                        <div className="lf downlf">
                            <DownOutlined />
                        </div>
                    </div>
                </Dropdown>
            </div>
        
        )
    }
} 


const stateToProps = (state)=>{
    return {
        user : state.user,
    }
}

const dispatchToProps =(dispatch)=>{
    return {
        logoutUser(val){
            // dispatch(reqLogin(val))
        },   
    }
}


export default connect(stateToProps, dispatchToProps)(MainHeader);
















